
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import {
  ApiBase,
  ApiInventory,
  ApiMerchant,
  ApiOrderAssign,
  ApiSalesOrders,
} from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { CommonHtmlType, NumberOrString } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { FulfillmentSource } from "@/core/directive/type/fulfillmentOrder";
import { getFulfillmentSourceRadio } from "@/core/directive/function/fulfillmentOrder";
import {
  SkuWarehouseOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { useRouter } from "vue-router";
import {
  modalShowListener,
  removeReadonly,
} from "@/core/directive/function/common";
import {
  merchantChannelData,
  merchantChannelOption,
  MerchantOrderlData,
} from "@/core/directive/interface/fulfillmentOrder";
import {
  OrderAssginTable,
  orderAssginTable,
} from "@/core/directive/interface/orderAssign";
import { DefiniteInventorySource } from "@/core/directive/type/salesOrder";
import {
  ProfitRate,
  ProfitRateChannel,
} from "@/core/directive/interface/merchant";
import CommonProfitRateOption from "@/components/layout/CommonProfitRateOption.vue";
import CommonProfitRateChannelOption from "@/components/layout/CommonProfitRateChannelOption.vue";
import store from "@/store";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  components: {
    CommonProfitRateOption,
    CommonProfitRateChannelOption,
    WindowResize,
  },
  name: "mb-order-routing-routing-overview-quick-routing",
  emits: ["update-list", "reset-form"],
  props: {
    user_type: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    tableItemData: {
      type: Object as () => OrderAssginTable,
      default: () => {
        return Object.assign({}, orderAssginTable);
      },
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const currentInstance: any = getCurrentInstance();
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const loading = ref(false);

    const editQuickRoutingRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);
    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };
    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const formData = ref({
      external_order_number: "",
      quantity: 1,
      sale_order_number: props.tableItemData.order_number,
      fulfillment_type: FulfillmentSource.Merchant,
      size: "",
      merchant: "",
      merchant_key: "",
      channel: "",
      channel_key: "",
      warehouse_key: "" as NumberOrString,
      inventory_type: "",
      merchant_order: "",
    });

    const options = ref({
      FulfillmentSource: FulfillmentSource,
      channel: [] as ProfitRateChannel[],
      channelDefault: [] as merchantChannelData[],
      channelLoading: false,
      merchant: [] as ProfitRate[],
      merchantMap: new Map<NumberOrString, ProfitRate>([]),
      merchantLoading: false,
      merchantDefault: [] as merchantChannelOption[],
      profit_rate: "",
      warehouse: [] as SkuWarehouseOption[],
      inventory_type: [] as TaggingItem[],
      fulfillment_type: getFulfillmentSourceRadio(t),
      size: [] as TaggingItem[],
      sizeLoading: false,
      sizeLoaded: false,
      sizeMap: new Map([]),
      merchantOrders: [] as MerchantOrderlData[],
    });

    const rules = ref({
      size: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      merchant: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.Merchant
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      channel: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.Merchant
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      warehouse_key: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.PreStock
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      inventory_type: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.PreStock
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      merchant_key: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.PreStock
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      channel_key: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.PreStock
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      merchant_order: [
        {
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value === "" &&
                formData.value.fulfillment_type === FulfillmentSource.PreStock
              ) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.sizeLoading) return cb([]);
      if (options.value.sizeLoaded) {
        let filterArr: TaggingItem[] = options.value.size.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.sizeLoading = true;
      const { data } = await ApiOrderAssign.getProductSkuList({
        brand_article_no: props.tableItemData.brand_article_no,
        merchant_article_no: props.tableItemData.merchant_article_no,
      });
      options.value.sizeLoading = false;
      if (data.code == 0) {
        let arr: TaggingItem[] = [];
        data.data.map((item) => {
          arr.push({
            value: item,
            label: item,
          });
        });
        options.value.size = arr;
        options.value.sizeLoaded = true;
        const filterArr: TaggingItem[] = options.value.size.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(formData.value.size.toUpperCase()) > -1
        );
        cb(filterArr);
      }
    };

    const isMerchantSourceDefaultData = computed(() => {
      let flag = false;
      if (store.getters.currentUser.user_type === 1) {
        flag = true;
      }
      return flag;
    });

    const getMerchantSourceData = async (value) => {
      if (isMerchantSourceDefaultData.value) {
        options.value.merchantMap.clear();
        options.value.merchant = [];
        options.value.merchantLoading = true;
        const params = {
          options: { include_fields: ["country_iso_2"] },
        };
        const { data } = await ApiBase.getMerchantSourceData(params);
        options.value.merchantLoading = false;
        if (data.code == 0) {
          options.value.merchant = data.data;
          data.data.map((item) => {
            options.value.merchantMap.set(item.value, item);
          });
        }
      } else {
        options.value.merchantMap.clear();
        options.value.merchant = [];
        options.value.merchantLoading = true;
        let params = {
          sku_id: props.tableItemData.sku_id,
          sales_price: (
            Number(props.tableItemData.base_row_total_incl_tax) /
            props.tableItemData.ordered_qty
          ).toFixed(2),
          tax_amount: (
            Number(props.tableItemData.base_row_tax_amount) /
            props.tableItemData.ordered_qty
          ).toFixed(2),
        };
        const { data } = await ApiMerchant.getProfitRateData({
          ...params,
        });
        options.value.merchantLoading = false;
        if (data.code == 0) {
          options.value.merchant = data.data;
          data.data.map((item: ProfitRate) => {
            options.value.merchantMap.set(item.merchant_id, item);
          });
        }
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["sku_inventory_type"],
      });
      if (data.code == 0) {
        options.value.inventory_type = data.data.sku_inventory_type.items;
      }
    };

    const getChannelAndMerchnatOrdersOptionsById = (
      data: any[],
      id: number
    ) => {
      // 找到指定的 item
      const target = data.find((item) => item.id === id);
      if (!target) {
        return {
          channels: [],
          merchantOrders: [],
        };
      }
      // 获取 channel 数组
      const channels = target.channel || [];
      // 从 channel 数组中提取所有的 merchant_order 数组
      const merchantOrders = channels.flatMap(
        (channel: any) => channel.merchant_order || []
      );

      return { channels, merchantOrders };
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.channel = "";
        options.value.profit_rate = "";
        options.value.channel = [];
      } else {
        formData.value.channel_key = "";
        options.value.channelDefault = [];
        options.value.merchantOrders = [];
      }
      if (formData.value.fulfillment_type === FulfillmentSource.Merchant) {
        if (isMerchantSourceDefaultData.value) {
          options.value.channelLoading = true;
          const { data } = await ApiMerchant.getSalesChannelsData({
            max_item: "all",
            merchant_id: id,
            status: "10",
          });
          options.value.channelLoading = false;
          if (data.code == 0) {
            options.value.channel = data.data;
          }
        } else {
          options.value.channel =
            (options.value.merchantMap.get(id) as ProfitRate)?.channel || [];
        }
      } else {
        const { channels, merchantOrders } =
          getChannelAndMerchnatOrdersOptionsById(
            options.value.merchantDefault,
            id
          );
        options.value.channelDefault = channels;
        options.value.merchantOrders = merchantOrders;
      }
    };

    const getSalesChannelsChange = (id) => {
      if (id) {
        options.value.channel.forEach((item) => {
          if (item.channel_id === id) {
            options.value.profit_rate = item.profit_rate;
          }
        });
      } else {
        options.value.profit_rate = "";
      }
    };

    const getInventoryOnHandSkuWarehouse = async () => {
      if (props.user_type === 1) return false;
      const { data } = await ApiInventory.getInventoryOnHandSkuWarehouse({
        sku_id: props.tableItemData.sku_id,
        order_id: props.tableItemData.order_id,
      });
      if (data.code == 0) {
        data.data.inventory.forEach((item, index) => {
          item.key = index;
        });
        options.value.warehouse = data.data.inventory;
      }
    };

    const getInventoryQty = computed(() => {
      let qty = 0,
        arr: string[] = [];
      options.value.warehouse.map((item) => {
        if (arr.indexOf(item.name) === -1) {
          arr.push(item.name);
        }
        qty += item.qty;
      });
      return {
        qty,
        arr,
      };
    });

    const transformMerchantData = (data: any[]) => {
      return data.map((item) => ({
        ...item,
        channel: Object.values(item.channel).map((channel: any) => ({
          ...channel,
          merchant_order: Object.values(channel.merchant_order),
        })),
      }));
    };

    const getFulfillmentOrderItemsSpotInventory = async (e) => {
      if (
        formData.value.warehouse_key !== "" &&
        formData.value.inventory_type !== "" &&
        formData.value.fulfillment_type === FulfillmentSource.PreStock
      ) {
        formData.value.merchant_key = "";
        formData.value.channel_key = "";
        options.value.merchantDefault = [];
        options.value.channelDefault = [];
        options.value.merchantOrders = [];
        const warehouseData = options.value.warehouse.find(
          (item) => item.key === formData.value.warehouse_key
        );
        const { data } =
          await ApiSalesOrders.getFulfillmentOrderItemsSpotInventory({
            sku_identifier: [props.tableItemData.sku_id],
            warehouse_id: warehouseData?.id,
            inventory_type: formData.value.inventory_type,
            status: warehouseData?.status,
            owner: warehouseData?.owner,
            sale_order_number: props.tableItemData.order_number,
          });
        if (data.code == 0) {
          options.value.merchantDefault = transformMerchantData(data.data);
        }
      }
    };

    const submitValidate = (callback) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          setSubmitAttribute();
          let params = {};
          if (formData.value.fulfillment_type === FulfillmentSource.Merchant) {
            if (isMerchantSourceDefaultData.value) {
              const merchantData = options.value.merchantMap.get(
                formData.value.merchant
              ) as any;
              params = {
                id: props.tableItemData.id,
                external_order_number: formData.value.external_order_number,
                sale_order_number: props.tableItemData.order_number,
                fulfillment_type: FulfillmentSource.Merchant,
                size: formData.value.size,
                merchant_profile: {
                  merchant: merchantData.value,
                  merchant_country: merchantData.entity_data.country_iso_2,
                  channel: formData.value.channel,
                },
                fulfilled_items: [
                  {
                    sku_identifier: props.tableItemData.sku_id,
                    quantity: formData.value.quantity,
                  },
                ],
              };
            } else {
              const merchantData = options.value.merchantMap.get(
                formData.value.merchant
              ) as ProfitRate;
              params = {
                id: props.tableItemData.id,
                external_order_number: formData.value.external_order_number,
                sale_order_number: props.tableItemData.order_number,
                fulfillment_type: FulfillmentSource.Merchant,
                size: formData.value.size,
                merchant_profile: {
                  merchant: merchantData.merchant_id,
                  merchant_country: merchantData.country_iso_2,
                  channel: formData.value.channel,
                },
                fulfilled_items: [
                  {
                    sku_identifier: props.tableItemData.sku_id,
                    quantity: formData.value.quantity,
                  },
                ],
              };
            }
          } else {
            const warehouseData = options.value.warehouse.find(
              (item) => item.key === formData.value.warehouse_key
            );
            const merchantData = options.value.merchantDefault.find(
              (item) => item.id === formData.value.merchant_key
            );
            const channelData = options.value.channelDefault.find(
              (item) => item.id === formData.value.channel_key
            );
            params = {
              id: props.tableItemData.id,
              external_order_number: formData.value.external_order_number,
              sale_order_number: props.tableItemData.order_number,
              fulfillment_type: FulfillmentSource.PreStock,
              merchant_profile: {
                merchant: formData.value.merchant_key,
                merchant_country: merchantData?.merchant_country,
                channel: formData.value.channel_key,
              },
              merchant_order: formData.value.merchant_order,
              fulfilled_items: [
                {
                  sku_identifier: props.tableItemData.sku_id,
                  quantity: formData.value.quantity,
                  base_unit_sales_order_price:
                    props.tableItemData.base_unit_sales_order_price,
                  max_qty_remained: props.tableItemData.open_qty,
                },
              ],
              warehouse: warehouseData?.id,
              inventory_type: formData.value.inventory_type,
              inventory_owner: channelData?.inventory_owner,
              inventory_status: channelData?.inventory_status,
            };
          }
          callback(params);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const sendRequest = () => {
      submitValidate((params) => {
        ApiOrderAssign.sendRequestItem(params)
          .then(({ data }) => {
            loading.value = false;
            setSubmitAttribute(false);
            if (data.code == 0) {
              resetForm();
              hideModal(editQuickRoutingRef.value);
              emit("update-list");
              const html = `<div class='text-start'><p>${t(
                t("fulfillmentOrder.merchant")
              )}: ${data.data.__show.merchant_name}</p><p>${t(
                "fulfillmentOrder.channel"
              )}: ${data.data.__show.channel_name}</p><p>${t(
                t("orderAssign.orderRequestId")
              )}: ${data.data.request_number}</p></div>`;
              Swal.fire({
                text: t("common.submitSuccess"),
                html: html,
                icon: "success",
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: t("orderAssign.createDraftGo"),
                cancelButtonText: t("common.discard"),
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-danger",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  // 默认生成的是Confirmed的订单执行
                  router.push(
                    "/merchant-order/order-processing/" +
                      data.data.id +
                      "/overview"
                  );
                }
              });
            } else if (data.code === 10000 || data.code === 40002) {
              Swal.fire({
                text: t("orderAssign.quickRoutingErrorTip"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("common.okGotIt"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              showServerErrorMsg(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    const submit = () => {
      submitValidate((params) => {
        ApiOrderAssign.quickRoutingItem(params)
          .then(({ data }) => {
            loading.value = false;
            setSubmitAttribute(false);
            if (data.code == 0) {
              showFormSubmitSuccessMsg(() => {
                resetForm();
                hideModal(editQuickRoutingRef.value);
                emit("update-list");
              });
            } else if (data.code === 10000 || data.code === 40002) {
              Swal.fire({
                text: t("orderAssign.quickRoutingErrorTip"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("common.okGotIt"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(async (result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              });
            } else {
              showServerErrorMsg(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    };

    const getFormInfo = async () => {
      if (
        props.tableItemData.definite_inventory_source ===
        String(DefiniteInventorySource.OnHand)
      ) {
        formData.value.fulfillment_type = FulfillmentSource.PreStock;
      }
      if (props.tableItemData.open_qty) {
        formData.value.quantity = props.tableItemData.open_qty;
      }
      // loading.value = true;
      Promise.all([getInventoryOnHandSkuWarehouse(), getMerchantSourceData("")])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      options.value.merchantDefault = [];
      options.value.channelDefault = [];
      options.value.merchantOrders = [];
      options.value.channel = [];
      options.value.warehouse = [];
      options.value.profit_rate = "";
      options.value.sizeLoaded = false;
      emit("reset-form");
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(editQuickRoutingRef.value);
    };

    onMounted(() => {
      modalShowListener(editQuickRoutingRef.value, () => {
        setTimeout(() => {
          getFormInfo();
        }, 10);
      });
      modalHideListener(editQuickRoutingRef.value, () => {
        resetForm();
      });
      getTaggingData();
    });

    return {
      t,
      cancelReadonly,
      FulfillmentSource,
      DefiniteInventorySource,
      props,
      editQuickRoutingRef,
      formRef,
      submitButton,
      loading,
      formData,
      rules,
      options,
      isMerchantSourceDefaultData,
      querySearchAsync,
      getSalesChannelsData,
      getSalesChannelsChange,
      getFulfillmentOrderItemsSpotInventory,
      getInventoryQty,
      handleDiscard,
      sendRequest,
      submit,
      searchMerchantSourceItems,
    };
  },
});
